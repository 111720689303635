<template>
<div>
  <b-container class="py-5 text-white">
    <b-row class="py-5">
      <b-col>
        <h1 class="py-2">Regulatory obligations</h1>
        <h4 class="py-2">Obligations compliance gap analysis</h4>
        <p>Peer comparison and remediation plan</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="pb-5">
          <b-row v-if="steps.data">
            <b-col>
              <h3>Load data</h3>
              <input v-if="!steps.loadedData" type="file" id="input"  @change="dataFileRegister" />
              <b-button variant="light" v-if="file && !steps.loadedData" @click="dataFileStream">Load</b-button>
              <div class="my-4" v-if="steps.loadingData">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <div v-if="steps.loadingData || steps.loadedData">
                processed rows of data: {{lines}}
              </div>
              <div v-if="steps.loadedData">
                <b-button class="mr-4" variant="light" v-if="file" @click="showMain">Show</b-button>
                <b-button variant="light" v-if="file" @click="dataFileReload">Reload</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="steps.loadingData" class="my-5 py-5">
            <b-col>
              <h3>Loading data</h3>
              <div><b-spinner label="Spinning"></b-spinner></div>
              <h4>processed rows of data: {{lines}}</h4>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import async from 'async'
import parse from 'csv-parse'

export default {
  components: {
  },
  computed: {
  },
  created: async function () {
    const recordsRaw = localStorage.getItem('frmcloudObligationsApp')
    if (recordsRaw) {
      this.recordsRaw = JSON.parse(recordsRaw)
      this.lines = this.recordsRaw.length
      this.steps.data = true
      this.steps.loadingData = false
      this.steps.loadedData = true
      this.file = true
    }

    this.parser = parse({
      columns: true,
      delimiter: ','
    })

    this.parser.on('readable', async () => {
      const recordRaw = this.parser.read()
      this.recordsRaw.push(recordRaw)
    })
  },
  data () {
    return {
      completed: false,
      file: null,
      lines: 0,
      loading: false,
      parser: null,
      reader: null,
      recordsRaw: [],
      steps: {
        data: true,
        loadingData: false,
        loadedData: false
      }
    }
  },
  methods: {
    dataFileRegister: function () {
      this.file = document.getElementById('input').files[0]
    },
    dataFileReload: function () {
      localStorage.removeItem('frmcloudObligationsApp')
      this.recordsRaw = []
      this.completed = false
      this.lines = null
      this.steps.data = true
      this.steps.loadingData = false
      this.steps.loadedData = false
      this.file = null
      this.stream = null
      this.reader = null
    },
    dataFileStream: async function () {
      this.steps.data = false
      this.steps.loadingData = true
      this.stream = await this.file.stream()
      this.reader = await this.stream.getReader()
      this.show = true
      await async.until(async.asyncify(this.testStream), async.asyncify(this.processChunk))
      this.steps.loadingData = false
      this.steps.loadedData = true
      this.steps.data = true
      this.lines = this.recordsRaw.length
      console.log(this.steps)
      const recordsRaw = this.recordsRaw
      console.log(recordsRaw)
      localStorage.setItem('frmcloudObligationsApp', JSON.stringify(recordsRaw))
    },
    processChunk: async function () {
      const chunk = await this.reader.read()
      if (chunk.done) {
        console.log('Stream complete')
        this.completed = true
        return
      }
      this.parser.write(chunk.value)
      this.lines = this.recordsRaw.length
    },
    showMain: function (app) {
      this.$router.push({ name: 'AppObligationsStart' })
    },
    testStream: function () {
      return this.completed
    }
  }
}
</script>
<style lang="scss" scoped>
a, .nav-link {
  color: white;
}
a:hover, .nav-link:hover {
  color: white;
}
</style>
